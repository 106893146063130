import "./font.css";
import React, { useState, useEffect, lazy, Suspense } from "react";
import ReactGA from "react-ga";
import { Route, Switch, useHistory } from "react-router-dom";
import { Backdrop, CssBaseline, IconButton } from "@material-ui/core";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getUserInfo } from "./api/login";
import UserContext from "./contexts/userContext";
import Intro from "./pages/main/intro";
import TeachingMethods from "./pages/main/teachingMethods";
import TeachersPage from "./pages/main/teachersPage";
import ContactUs from "./pages/main/contactUs";
import StudentsPage from "./pages/main/studentsPage";
import Products from "./pages/main/products";
import Login from "./pages/main/login";
import Register from "./pages/main/register";
import ForgotPassword from "./pages/main/forgotPassword";
import Error from "./pages/main/error";
import Welcome from "./pages/dashboard/welcome";
 
import ConfirmMail from "./pages/main/confirmMail";
import Navbar from "./components/main/navbar";
import Notification from "./components/dashboard/notification";
import AlertContext from "./contexts/alertContext";
import useAlert from "./hooks/useAlert";
import { ThemeProvider } from "./contexts/themeContext";
import StudentProfile from "./pages/dashboard/studentProfile";
 

const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const Session = lazy(() => import("./pages/dashboard/session"));
const JudgeProfile = lazy(() => import("./pages/dashboard/judgeProfile"));
const Studio = lazy(() => import("./pages/studio/studio"));

function App() {
  const history = useHistory();
  const [user, setUser] = useState({});
  const { setAlert, AlertComponent } = useAlert();
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
    if (!initialLoad) {
      ReactGA.pageview(history.location.pathname);
      setInitialLoad(true);
    }
    updateUser();
  }, [history]);

  async function updateUser() {
    const serverResponse = await getUserInfo().catch(() => {});
    if (serverResponse) {
      const { response, error } = serverResponse;
      if (!error && response) setUser(response.user);
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <UserContext.Provider value={{ user, updateUser }}>
        <ThemeProvider>
          <Suspense
            fallback={
              <Backdrop style={{ backgroundColor: "#170a22e6" }} open>
                <img
                  className="loading--logo"
                  src="/static/logos/speechtopia-logo.png"
                />
              </Backdrop>
            }
          >
            <AlertContext.Provider value={{ setAlert }}>
              <CssBaseline />
              <Switch>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route exact path="/welcome">
                  <Welcome />
                </Route>
 
                <Route path="/sessions">
                  <Route path="/sessions/PS-1/:id">
                    <Session />
                  </Route>
                  <Route path="/sessions/BP-1/:id">
                    <Session />
                  </Route>
                </Route>
                <Route path="/studio">
                  <Studio />
                </Route>
                <div className="app">
                  <Route path="/jp/:id">
                    <JudgeProfile />
                  </Route>
                  <Route path="/sp/:id">
                    <StudentProfile />
                  </Route>
                  <Route path="/">
                    <Navbar />
                    <IconButton
                      color="primary"
                      onClick={() => history.push("/contact-us")}
                      style={{
                        backgroundColor: "#26003e",
                        radius: "100%",
                        position: "fixed",
                        right: "2rem",
                        bottom: "2rem",
                        zIndex: 10000,
                      }}
                    >
                      <PhoneInTalkOutlinedIcon
                        fontSize="large"
                        color="primary"
                      />
                    </IconButton>
                  </Route>
                  <Route exact path="/">
                    <Intro />
                  </Route>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/forgot-password">
                    <ForgotPassword />
                  </Route>
                  <Route exact path="/unconfirmed">
                    <ConfirmMail />
                  </Route>
                  <Route exact path="/students">
                    <StudentsPage />
                  </Route>
                  <Route exact path="/teachers">
                    <TeachersPage />
                  </Route>
                  <Route exact path="/products">
                    <Products />
                  </Route>
                  <Route exact path="/methodology">
                    <TeachingMethods />
                  </Route>
                  <Route exact path="/contact-us">
                    <ContactUs />
                  </Route>
                  <Route path="/error">
                    <Error />
                  </Route>
                </div>
              </Switch>
              <AlertComponent />
            </AlertContext.Provider>
          </Suspense>
          <Notification />
        </ThemeProvider>
      </UserContext.Provider>
    </MuiPickersUtilsProvider>
  );
}

export default App;

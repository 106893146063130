import { useState } from "react";

const useInput = (state) => {
  const [input, setInput] = useState(state);

  function handleChange(event) {
    setInput({
      ...input,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  }
  return [input, handleChange];
};

export default useInput;

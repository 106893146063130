import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";
import { ReactComponent as DiamondIcon } from "../../../icons/speechtopia-diamond.svg";
import { ReactComponent as BooksIcon } from "../../../icons/speechtopia-book.svg";
import { ReactComponent as PenIcon } from "../../../icons/speechtopia-pen.svg";
import { ReactComponent as SpeechIcon } from "../../../icons/speechtopia-speech.svg";
import { ReactComponent as BulbIcon } from "../../../icons/speechtopia-bulb.svg";
import LinkTo from "../../components/main/linkTo";
import useWindow from "../../hooks/useWindow";

const TeachingMethods = () => {
  const { isTablet } = useWindow();
  const { t } = useTranslation("static");
  const { t: tc } = useTranslation("common");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="teaching-methods">
      <Helmet>
        <title>Methodology - Speechtopia</title>
      </Helmet>
      <h1 className="teaching-methods--title">{tc("titles.methodology")}</h1>
      <p className="teaching-methods--description">
        <Trans
          i18nKey="static:methodology.description"
          defaultValue="Foundational, fundamental skills for Speech and Debate are introduced in
            the <strong>Theoretical Class</strong>, designed by our world-class
            Speech/Debate coaches and curriculum developers.
            <strong> Challenge Cards</strong> put that theoretical learning to work.
            <strong> Critical Research</strong> deep-dives into the subject.
            <strong> Practice Class</strong> is a structured Debate or Speaking
            Event (depending on the learning track) and judged by our World-Class
            speech/debate coaches. Afterwards targeted feedback reinforces the other
            three steps."
          components={{ bold: <strong style={{ color: "#00eaf2" }} /> }}
        />
      </p>
      <div className="teaching-methods__outline">
        <span className="teaching-methods__outline--circle">
          {t("methodology.circles.theoreticalClass")}
        </span>
        <span className="teaching-methods__outline--circle">
          {t("methodology.circles.challengeCard")}
        </span>
        <span className="teaching-methods__outline--circle">
          {t("methodology.circles.practiceClass")}
        </span>
        <span className="teaching-methods__outline--circle">
          {t("methodology.circles.criticalResearch")}
        </span>
      </div>
      <h1 className="teaching-methods--subtitle">
        {t("methodology.subtitles.skillsWeTeach")}
      </h1>
      <ul className="teaching-methods__skills">
        <li className="teaching-methods__skills--bar">
          {t("methodology.skills.dramaticInterpretation")}
        </li>
        <li className="teaching-methods__skills--bar">
          {t("methodology.skills.textualInterpretation")}
        </li>
        <li className="teaching-methods__skills--bar">
          {t("methodology.skills.rhetoric")}
        </li>
        <li className="teaching-methods__skills--bar">
          {t("methodology.skills.matterAndInsight")}
        </li>
        <li className="teaching-methods__skills--bar">
          {t("methodology.skills.audienceEngagement")}
        </li>
        <li className="teaching-methods__skills--bar">
          {t("methodology.skills.argumentation")}
        </li>
      </ul>
      <h1 className="teaching-methods--subtitle">
        {t("methodology.subtitles.ourCurriculumSystem")}
      </h1>
      <div className="teaching-methods__curriculum">
        <div className="teaching-methods__curriculum__titles">
          <span>{t("methodology.curriculum.stages")}</span>
          <div className="teaching-methods__curriculum__titles__chart">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="teaching-methods__curriculum__ages">
          <span>{t("methodology.curriculum.ages")}</span>
          <div className="teaching-methods__curriculum__ages__list">
            <span>6 - 9</span>
            <span>10 - 11</span>
            <span>12 - 13</span>
            <span>14 - 15</span>
            <span>15+</span>
          </div>
        </div>
        <div className="teaching-methods__curriculum__data">
          <span>
            {!isTablet && <PenIcon />}{" "}
            {t("methodology.curriculum.readingAndWriting")}
          </span>
          <div className="teaching-methods__curriculum__data__stages">
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
          </div>
        </div>
        <div className="teaching-methods__curriculum__data">
          <span>
            {!isTablet && <DiamondIcon />}{" "}
            {t("methodology.curriculum.poiseAndConfidence")}
          </span>
          <div className="teaching-methods__curriculum__data__stages">
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
          </div>
        </div>
        <div className="teaching-methods__curriculum__data">
          <span>
            {!isTablet && <SpeechIcon />}{" "}
            {t("methodology.curriculum.speechSkills")}
          </span>
          <div className="teaching-methods__curriculum__data__stages">
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
          </div>
        </div>
        <div className="teaching-methods__curriculum__data">
          <span>
            {!isTablet && <BooksIcon />}{" "}
            {t("methodology.curriculum.humanitiesInterest")}
          </span>
          <div className="teaching-methods__curriculum__data__stages">
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
          </div>
        </div>
        <div className="teaching-methods__curriculum__data">
          <span>
            {!isTablet && <BulbIcon />}{" "}
            {t("methodology.curriculum.metaDevelopment")}
          </span>
          <div className="teaching-methods__curriculum__data__stages">
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--quarter" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle circle--half" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
            <span className="teaching-methods__curriculum__data__stages__circle" />
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default TeachingMethods;

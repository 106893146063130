import React, { useState, useContext } from "react";
import ReactGA from "react-ga";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextField, Button, Typography } from "@material-ui/core";
import { register } from "../../api/register";
import useInput from "../../hooks/useInput";
import useWindow from "../../hooks/useWindow";
import UserContext from "../../contexts/userContext";
import AlertContext from "../../contexts/alertContext";

const Register = () => {
  const { setAlert } = useContext(AlertContext);
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");
  const { updateUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [data, handleChange] = useInput();
  const { isMobile } = useWindow();
  const history = useHistory();

  function validateInput() {
    const { password, repeatPassword } = data;
    // const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    // if (!regex.test(password)) {
    //   setAlertMessage(t("common.passwordPattern"));
    //   return false;
    // }
    if (password !== repeatPassword) {
      setAlert({ message: t("common.passwordsDontMatch"), error: true });
      return false;
    }
    return true;
  }

  async function handleRegister(event) {
    setLoading(true);
    event.preventDefault();
    if (validateInput()) {
      const { response, error } = await register(data);
      if (error) {
        ReactGA.event({
          category: "User",
          action: "Register",
          label: "Failed",
          transport: "beacon",
        });
        setAlert({ message: response, error });
        setLoading(false);
      } else {
        ReactGA.event({
          category: "User",
          action: "Register",
          label: "Success",
          transport: "beacon",
        });
        updateUser();
        history.push("/unconfirmed/");
      }
    } else {
      setLoading(false);
    }
  }

  return (
    <div className="register">
      <img
        className="register--astronaut"
        alt="speechtopia login astronaut"
        src="/static/vectors/speechtopia-astronaut-login.png"
      />
      {!isMobile && (
        <img
          className="register--planet"
          alt="speechtopia login planet"
          src="/static/vectors/speechtopia-planet-login.png"
        />
      )}
      <img
        className="register--rocket"
        alt="speechtopia login rocket"
        src="/static/vectors/speechtopia-rocket-login.png"
      />
      <form className="register__form" onSubmit={handleRegister}>
        <h1>{t("common.speechtopiaPlanet")}</h1>
        <h3>{tc("titles.register")}</h3>
        <TextField
          label={t("common.email")}
          name="email"
          type="email"
          helperText={`${t("register.example")}: support@speechtopia.com`}
          required
          autoComplete="off"
          size="small"
          variant="standard"
          onChange={handleChange}
        />
        <TextField
          label={t("common.password")}
          name="password"
          type="password"
          required
          size="small"
          variant="standard"
          onChange={handleChange}
          inputProps={{ minLength: 4 }}
        />
        <TextField
          label={t("common.repeatPassword")}
          name="repeatPassword"
          type="password"
          required
          size="small"
          variant="standard"
          onChange={handleChange}
        />
        {/* <FormControlLabel
          className={classes.label}
          control={
            <Checkbox
              required
              onChange={handleChange}
              name="terms"
              color="primary"
            />
          }
          label={t("register.terms")}
        /> */}
        <Typography
          style={{ margin: "0.5rem 0", paddingLeft: "0.25rem" }}
          variant="caption"
          component="small"
        >
          {t("register.terms")}
        </Typography>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {tc("titles.register")}
        </Button>
        <div className="register__form__details">
          <small>
            {t("common.alreadyHaveAccount")}
            <Link className="register__form__details--link" to="/login/">
              {tc("titles.login")}
            </Link>
          </small>
        </div>
      </form>
    </div>
  );
};

export default React.memo(Register);

import axios from "axios";

axios.defaults.validateStatus = (status) => status < 500;

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

import React, { useEffect } from "react";
import LinkTo from "../../components/main/linkTo";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation("static");
  const { t: tc } = useTranslation("common");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="products">
      <div className="products__container">
        <div className="products__container--image" />
        <h1 className="products__container--title">{t("products.title")}</h1>
        <h3 className="products__container--subtitle">
          {t("products.subtitle")}
        </h3>
      </div>
      <LinkTo to="/" text={tc("titles.speechtopia")} />
    </div>
  );
};

export default Products;

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { resendConfirmation } from "../../api/register";
import useWindow from "../../hooks/useWindow";
import AlertContext from "../../contexts/alertContext";
import UserContext from "../../contexts/userContext";

const ConfirmMail = () => {
  const [showButton, setShowButton] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(0);
  const { isMobile } = useWindow();
  const { user } = useContext(UserContext);

  async function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    const serverResponse = await resendConfirmation().catch(() => {
      setAlert({ message: tc("alerts.internalServerError"), error: true });
    });
    if (serverResponse) {
      const { response, error } = serverResponse;
      setAlert({ message: response, error });
    }

    setTime(30);
    setLoading(false);
  }

  // After request was sent to server, the timer will go off and using settimeout and useeffect,
  //Time counts down till 0 (time !==0 is set so that on first render, countdown does not go off)
  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  return (
    <div className="confirm-mail">
      <img
        className="confirm-mail--astronaut"
        alt="speechtopia login astronaut"
        src="/static/vectors/speechtopia-astronaut-login.png"
      />
      {!isMobile && (
        <img
          className="login--planet"
          alt="speechtopia login planet"
          src="/static/vectors/speechtopia-planet-login.png"
        />
      )}
      <img
        className="confirm-mail--rocket"
        alt="speechtopia login rocket"
        src="/static/vectors/speechtopia-rocket-login.png"
      />
      <form className="confirm-mail__form" onSubmit={handleSubmit}>
        <Typography variant="h5" component="h1">
          {t("confirmMail.title")}
        </Typography>
        <Typography
          style={{ fontWeight: "bold" }}
          align="center"
          color="secondary"
        >
          {user.email}
        </Typography>
        <div className="confirm-mail__resend">
          <span>{t("confirmMail.subtitle")}</span>
          <small onClick={() => setShowButton(true)}>
            {t("confirmMail.tryAgain")}
          </small>
        </div>
        {showButton && (
          <Button
            disabled={time !== 0 || loading}
            type="submit"
            variant="contained"
            color="primary"
          >
            {time !== 0
              ? `${t("confirmMail.resendConfirmation")} ${time}`
              : t("confirmMail.resendConfirmation")}
          </Button>
        )}
      </form>
    </div>
  );
};

export default React.memo(ConfirmMail);

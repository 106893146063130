import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "../../api/user";
import { TextField, Button } from "@material-ui/core";
import useInput from "../../hooks/useInput";
import useWindow from "../../hooks/useWindow";
import AlertContext from "../../contexts/alertContext";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");
  const [data, handleChange] = useInput();
  const { isMobile } = useWindow();

  async function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    const { newPassword, repeatPassword } = data;
    // const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    // if (!regex.test(newPassword)) {
    //   setAlertMessage(t("common.passwordPattern"));
    //   setErrorAlert(true);
    // }

    if (repeatPassword !== newPassword)
      setAlert({ message: t("common.passwordsDontMatch"), error: true });
    else {
      const serverResponse = await forgotPassword(data).catch(() => {
        setAlert({ message: tc("alerts.internalServerError"), error: true });
      });
      if (serverResponse) {
        const { response, error } = serverResponse;
        setAlert({ message: response, error });
      }
    }
    setLoading(false);
  }

  return (
    <div className="forgot-password">
      <img
        className="forgot-password--astronaut"
        alt="speechtopia login astronaut"
        src="/static/vectors/speechtopia-astronaut-login.png"
      />
      {!isMobile && (
        <img
          className="forgot-password--planet"
          alt="speechtopia login planet"
          src="/static/vectors/speechtopia-planet-login.png"
        />
      )}
      <img
        className="forgot-password--rocket"
        alt="speechtopia login rocket"
        src="/static/vectors/speechtopia-rocket-login.png"
      />
      <form className="forgot-password__form" onSubmit={handleSubmit}>
        <h1>{t("common.speechtopiaPlanet")}</h1>
        <h3>{t("forgotPassword.recover")}</h3>
        <TextField
          label={t("common.email")}
          name="email"
          type="email"
          helperText={t("forgotPassword.associatedEmail")}
          autoComplete="off"
          required
          size="small"
          variant="standard"
          onChange={handleChange}
        />
        <TextField
          label={t("common.newPassword")}
          name="newPassword"
          type="password"
          autoComplete="off"
          // helperText={t("common.passwordPattern")}
          required
          size="small"
          variant="standard"
          onChange={handleChange}
        />
        <TextField
          label={t("common.repeatPassword")}
          name="repeatPassword"
          type="password"
          autoComplete="off"
          required
          size="small"
          variant="standard"
          onChange={handleChange}
        />
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          style={{ borderRadius: "3rem" }}
        >
          {t("forgotPassword.resetPassword")}
        </Button>
      </form>
    </div>
  );
};

export default React.memo(ForgotPassword);

import React, { useContext, useState, useEffect } from "react";
import {
  DashboardOutlined as DashboardIcon,
  ExitToAppOutlined as ExitToAppIcon,
  SettingsOutlined as SettingsIcon,
  TranslateOutlined as TranslateIcon,
  HelpOutlineOutlined,
} from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import {
  IconButton,
  MenuItem,
  Menu,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { logout } from "../../api/user";
import UserContext from "../../contexts/userContext";
import { isEmpty } from "../../util/objects";

const useStyles = makeStyles((theme) => ({
  link: { color: grey[100] },
  icon: { marginRight: "0.25rem" },

  avatar: {
    [theme.breakpoints.down("sm")]: {
      width: "2.5rem",
      height: "2.5rem",
    },
  },

  langIcon: {
    margin: "0 1.5rem",
    padding: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0 .1rem",
    },
  },
}));

const Navbar = () => {
  const { user } = useContext(UserContext);
  const { t, i18n } = useTranslation("common");
  const [loggedInUser, setLoggedInUser] = useState({});
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for menu element after clicking on user avatar
  const [anchorLang, setAnchorLang] = useState(null);
  const menuOpen = Boolean(anchorEl); //Chcking to see if menu is open
  const langMenuOpen = Boolean(anchorLang);
  const classes = useStyles();

  async function handleLogOut() {
    const { error } = await logout();
    if (!error) {
      window.location.replace("/login");
    }
  }

  useEffect(() => {
    setLoggedInUser(user);
  }, [user]);

  return (
    <div className="nav">
      <div className="nav__left">
        <NavLink
          exact
          to="/"
          className="nav__left__item"
          activeClassName="nav__left__item--active"
        >
          {t("titles.home")}
        </NavLink>
        <NavLink
          to="/teachers"
          className="nav__left__item"
          activeClassName="nav__left__item--active"
        >
          {t("titles.teachers")}
        </NavLink>
        <NavLink
          to="/students"
          className="nav__left__item"
          activeClassName="nav__left__item--active"
        >
          {t("titles.students")}
        </NavLink>
        <NavLink
          to="/methodology"
          className="nav__left__item"
          activeClassName="nav__left__item--active"
        >
          {t("titles.methodology")}
        </NavLink>
        <NavLink
          to="/contact-us"
          className="nav__left__item"
          activeClassName="nav__left__item--active"
        >
          {t("titles.contactUs")}
        </NavLink>
      </div>
      <Menu
        id="language-appbar"
        anchorEl={anchorLang}
        keepMounted
        open={langMenuOpen}
        onClose={() => setAnchorLang(null)}
      >
        <MenuItem
          onClick={() => {
            i18n.changeLanguage("zh_HANS");
            setAnchorLang(null);
          }}
        >
          简体中文
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18n.changeLanguage("en");
            setAnchorLang(null);
          }}
        >
          English
        </MenuItem>
      </Menu>
      <IconButton
        className={classes.langIcon}
        onClick={(event) => setAnchorLang(event.currentTarget)}
      >
        <TranslateIcon />
      </IconButton>
      <a
        href="https://blog.speechtopia.club/support-center/"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <IconButton className={classes.langIcon}>
          <HelpOutlineOutlined />
        </IconButton>
      </a>
      {isEmpty(loggedInUser) ? ( // Checks if user's object is empty (meaning it is logged out)
        <NavLink className="nav--login" to="/login">
          {t("titles.login")}
        </NavLink>
      ) : (
        <React.Fragment>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            color="inherit"
          >
            <Avatar
              className={classes.avatar}
              alt="User Avatar"
              src={user.avatar}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={menuOpen}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                window.location.replace("/dashboard");
              }}
            >
              <DashboardIcon className={classes.icon} /> {t("titles.dashboard")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                window.location.replace("/dashboard/account-setting");
              }}
            >
              <SettingsIcon className={classes.icon} />
              {t("titles.accountSetting")}
            </MenuItem>
            <MenuItem onClick={handleLogOut}>
              <ExitToAppIcon className={classes.icon} /> {t("titles.logout")}
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(Navbar);

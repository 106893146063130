import React, { useReducer } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const useAlert = () => {
  const [alert, setAlert] = useReducer(stateReducer, {
    isOpen: false,
    message: "",
    error: false,
    duration: 4000,
  });

  function stateReducer(
    state,
    { isOpen = true, message = "", error = false, duration = 4000 }
  ) {
    return { isOpen, message, error, duration };
  }

  function AlertComponent({ ...props }) {
    return (
      <Snackbar
        open={alert.isOpen}
        autoHideDuration={alert.duration}
        onClose={() => setAlert({ isOpen: false })}
        {...props}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setAlert({ isOpen: false })}
          severity={alert.error ? "error" : "success"}
        >
          {alert.message}
        </MuiAlert>
      </Snackbar>
    );
  }

  return { setAlert, AlertComponent };
};

export default useAlert;

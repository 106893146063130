import { useState } from "react";

const useCheck = (state) => {
  const [input, setInput] = useState(state);

  function handleChange(event) {
    setInput({
      ...input,
      [event.target.name]: event.target.checked,
    });
  }
  return [input, handleChange];
};

export default useCheck;

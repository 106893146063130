import React from "react";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import countries from "../../util/countries";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  countryOption: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  inputRoot: {
    "& > div": {
      width: "100%",
    },
  },
  stageText: {
    textAlign: "center",
  },
});

const WelcomeOrganizer = ({
  role,
  step,
  handleChange,
  data,
  handleBirthDate,
  birthDate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");

  return (
    <div
      className={`welcome__operations__forms__form${
        step === 1 && role === "organizer" ? "" : "--hidden"
      }`}
    >
      <h3>
        {t("welcome.common.registeringAs", {
          role: tc("roles.organizer"),
        })}
      </h3>
      <TextField
        label={t("forms.firstName")}
        name="firstName"
        type="text"
        value={data.firstName}
        required
        size="small"
        autoComplete="off"
        variant="standard"
        className={classes.inputRoot}
        onChange={handleChange}
      />
      <TextField
        label={t("forms.lastName")}
        name="lastName"
        value={data.lastName}
        type="text"
        required
        size="small"
        autoComplete="off"
        variant="standard"
        className={classes.inputRoot}
        onChange={handleChange}
      />
      <TextField
        label={t("forms.teacherId")}
        name="teacherId"
        value={data.teacherId}
        type="text"
        size="small"
        autoComplete="off"
        variant="standard"
        className={classes.inputRoot}
        onChange={handleChange}
      />
      <KeyboardDatePicker
        disableFuture
        inputvariant="standard"
        name="birthDate"
        size="small"
        label={t("forms.birthDate")}
        value={birthDate}
        onChange={handleBirthDate}
        minDate="01/01/1980"
        className={classes.inputRoot}
        format="MM/dd/yyyy"
      />
      <FormControl
        size="small"
        variant="standard"
        className={classes.inputRoot}
      >
        <InputLabel id="country-label">{t("forms.country")}</InputLabel>
        <Select
          labelId="country-label"
          native
          onChange={handleChange}
          value={data.country}
          name="country"
        >
          {countries.map((country) => {
            return (
              <option key={country.code} value={country.label}>
                {country.label} ({country.code})
              </option>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        label={t("forms.state")}
        name="state"
        value={data.state}
        type="text"
        required
        size="small"
        autoComplete="off"
        variant="standard"
        className={classes.inputRoot}
        onChange={handleChange}
      />
      <TextField
        label={t("forms.city")}
        name="city"
        value={data.city}
        type="text"
        required
        size="small"
        autoComplete="off"
        variant="standard"
        className={classes.inputRoot}
        onChange={handleChange}
      />
      <TextField
        label={t("forms.address")}
        name="street"
        value={data.street}
        type="text"
        required
        size="small"
        autoComplete="off"
        variant="standard"
        className={classes.inputRoot}
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(WelcomeOrganizer);

export function calculateScrollProgress() {
  const documentHeight = getDocumentHeight();
  const scrollTop = window.pageYOffset;
  const windowHeight = window.innerHeight;
  const scrollBarHeight = documentHeight - windowHeight;
  return (scrollTop / scrollBarHeight) * 100;
}

function getDocumentHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

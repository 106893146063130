export function getCriteria(inputId) {
  return ccCriteria.find((criteria) => {
    return criteria?.id?.toString() === inputId?.toString();
  });
}

export const ccCriteria = [
  { name: "Framing", id: 1 },
  { name: "Rule Of Threes", id: 2 },
  { name: "Pausing", id: 3 },
  { name: "Volume", id: 4 },
  { name: "Pace", id: 5 },
  { name: "Tone", id: 6 },
  { name: "Enunciation", id: 7 },
  { name: "Pronunciation", id: 8 },
  { name: "Eye Contact", id: 9 },
  { name: "Facial Expressions", id: 10 },
  { name: "Gestures", id: 11 },
  { name: "Posture", id: 12 },
  { name: "Appearance", id: 13 },
  { name: "Respect", id: 14 },
  { name: "Roadmap", id: 15 },
  { name: "Signpost", id: 16 },
  { name: "Conclusion", id: 17 },
  { name: "Statement Length", id: 18 },
  { name: "Memorable", id: 19 },
  { name: "Source Credibility", id: 20 },
  { name: "Point Relevancy", id: 21 },
  { name: "Evidence To Statement Link", id: 22 },
  { name: "Impacting", id: 23 },
  { name: "Uniqueness", id: 24 },
  { name: "Solvency", id: 25 },
  { name: "Harms", id: 26 },
  { name: "Boolean Answering", id: 27 },
  { name: "Leading Questions", id: 28 },
  { name: "Question Complexity", id: 29 },
  { name: "Creativity", id: 30 },
  { name: "Knifing", id: 31 },
  { name: "Point", id: 32 },
  { name: "Choice of Args", id: 33 },
  { name: "Hung Case", id: 34 },
  { name: "Order of Points", id: 35 },
  { name: "Organization", id: 36 },
  { name: "Argumentation", id: 37 },
  { name: "Claim", id: 38 },
  { name: "Warrant", id: 39 },
  { name: "Linking", id: 40 },
  { name: "Analysis", id: 41 },
  { name: "Characterization", id: 42 },
  { name: "Comparative", id: 43 },
  { name: "Segmentation", id: 44 },
  { name: "Weighing", id: 45 },
  { name: "Support", id: 46 },
  { name: "Rebuttal", id: 47 },
  { name: "Clash", id: 48 },
  { name: "Refutation", id: 49 },
  { name: "Vocal Intonation", id: 50 },
  { name: "Clarity", id: 51 },
  { name: "Rhetoric", id: 52 },
  { name: "Physicality", id: 53 },
  { name: "Etiquette", id: 54 },
  { name: "Poi", id: 55 },
  { name: "Poi Complexity", id: 56 },
  { name: "Caseline", id: 57 },
  { name: "Teamwork", id: 58 },
  { name: "Time Splits", id: 59 },
  { name: "Consistency", id: 60 },
  { name: "Focus", id: 61 },
  { name: "Themes", id: 62 },
  { name: "Crux", id: 63 },
];

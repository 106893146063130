import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as TrophyIcon } from "../../../icons/speechtopia-trophy.svg";
import LinkTo from "../../components/main/linkTo";
import { useTranslation, Trans } from "react-i18next";
import { Box, Divider, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    width: "100%",
    fontWeight: "normal",
    fontSize: "1.3rem",
    textTransform: "uppercase",
  },
  value: {
    // width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  wechatCode: {
    width: "50%",
    margin: "0.5rem auto",
    marginBottom: "2rem",
  },
});

const StudentsPage = () => {
  const { t } = useTranslation("static");
  const { t: tc } = useTranslation("common");
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="students-intro">
      <Helmet>
        <title>Students - Speechtopia</title>
      </Helmet>
      <img
        src="/static/vectors/speechtopia-astronaut-student.png"
        alt="Speechtopia Debate Students"
        className="students-intro--image"
      />
      <h1 className="students-intro--title">{tc("titles.students")}</h1>
      <div className="students-intro__students">
        <h3 className="students-intro__students--subtitle">
          {t("students.subtitles.experience")}
        </h3>
        <p className="students-intro__experience--description">
          <Trans
            i18nKey="static:students.experienceDescription"
            defaultValue="Our Universe needs Explorers! Speechtopia is a wholly reimagined ecosystem of expanding apps. We seek to cultivate <strong> Self-Confidence</strong>,<strong> Self-Awareness </strong> and <strong> Self-Advocacy</strong> from our Speechtopian explorers (students). Empathy driven leaders, ready for an uncertain and changing future, prepared and outfitted!"
            components={{ bold: <strong style={{ color: "#00eaf2" }} /> }}
          />
        </p>

        <h3 className="students-intro__students--subtitle">
          {t("students.subtitles.achievements")}
        </h3>
       

        <ul className="students-intro__students__list">
          <li>
            <TrophyIcon />
          </li>

          <Trans
            i18nKey="static:students.achievementsList"
            defaultValue="<li>Member of Canadian national team for debate</li>
            
            <li>
              National Champion - 2019 CCTV “Star of Outlook” National English
              Talent Contest
            </li>
            <li>Breaking finalist - Tournament of Champions</li>
            <li>National finalist - National Forensics League</li>
            <li>
              Beijing champion, first and second runners-up of 2020 China Daily
              “21st Century Cup” National English Public Speaking Contest
            </li>
            <li>
              17 first prize winners, 13 second prize winners, 3 third prize
              winners in various competitions
            </li>"
            components={{ item: <li /> }}
          />
          <li>
            <TrophyIcon />
          </li>
        </ul>
        <p className="students-intro__students__achievements--description">
          {t("students.achievementsDescription")}
        </p>
      </div>
   
    </div>
  );
};

export default StudentsPage;

/*eslint-disable no-useless-escape*/

import { parsePhoneNumberFromString } from "libphonenumber-js";

const rootUrl = window.origin;

function clearUrlParameters() {
  var uri = window.location.toString();
  if (uri.indexOf("?") > 0) {
    var clean_uri = uri.substring(0, uri.indexOf("?"));
    window.history.replaceState({}, document.title, clean_uri);
  }
}

function extractUrlParameter(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function pascal(string) {
  if (string) {
    return string.replace(
      /\w\S*/g,
      (value) => value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
    );
  }
}

function extractPhoneNumber(input) {
  const phoneNumber = parsePhoneNumberFromString(input);
  return phoneNumber ? phoneNumber?.formatNational().replace(/ /g, "") : input;
}

function copyToClipboard(string) {
  // function works as first we make a textarea element and set its styles in a way that brings minimum visibility on screen
  // Then put the string into it, copy it using.execCommand(), and there you have the text on your clipboard :)
  // Afterwards, we would remove the element from body, close more and remove message from state
  const textArea = document.createElement("textarea");
  textArea.style.position = "absolute";
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = 0;
  textArea.style.border = "none";
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";
  textArea.setAttribute("readonly", "");
  textArea.value = string;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  const successful = document.execCommand("copy");
  document.body.removeChild(textArea);
  return successful;
}

export {
  copyToClipboard,
  extractPhoneNumber,
  pascal,
  extractUrlParameter,
  clearUrlParameters,
  rootUrl,
};

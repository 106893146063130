import api from "./apiService";

export async function login(inputData) {
  const { data, status } = await api.post("/login/", { ...inputData });
  return { response: data.response, error: status >= 300, code: status };
}

export async function loginSMS(inputData) {
  const { data, status } = await api.post("/api/auth/verification/", {
    ...inputData,
  });
  return {
    response: data.response,
    error: status >= 300 && status !== 449,
    code: status,
  };
}

export async function getAuthSMS(phone) {
  const { data, status } = await api.get(
    `/api/auth/send-sms?phoneNumber=${phone}`
  );
  return { response: data.response, error: status >= 300 };
}

export async function getUserInfo() {
  const { data, status } = await api.get("/get_user_info/");
  return { response: data.response, error: status >= 300 };
}

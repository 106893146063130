import { useTranslation } from "react-i18next";

const useTableOptions = () => {
  const { t: tc } = useTranslation("common");

  const styling = {
    rowStyle: { backgroundColor: "transparent" },
  };

  const localization = {
    header: { actions: tc("actions.actions") },
    body: {
      deleteTooltip: tc("actions.delete"),
      editTooltip: tc("actions.edit"),
      editRow: {
        cancelTooltip: tc("actions.cancel"),
        saveTooltip: tc("actions.save"),
      },
    },
    toolbar: {
      searchPlaceholder: tc("actions.search"),
      searchTooltip: tc("actions.search"),
      exportTitle: tc("actions.export"),
    },
  };

  const options = {
    sorting: true,
    exportButton: true,
    paginationType: "stepped",
  };
  return { localization, options, styling };
};

export default useTableOptions;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { extractUrlParameter } from "../../util/strings";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  message: {
    margin: "1rem",
    marginBottom: "2rem",
  },
  title: {
    fontWeight: "bold",
    margin: "1rem",
  },
});

const Error = () => {
  const classes = useStyles();
  const { t: tc } = useTranslation("common");
  const [error, setError] = useState({ code: "", message: "" });

  useEffect(() => {
    const code = extractUrlParameter("code");
    const message = extractUrlParameter("msg");
    setError({ code, message });
  }, []);

  return (
    <div className="login">
      <img
        className="login--astronaut"
        alt="speechtopia login astronaut"
        src="/static/vectors/speechtopia-astronaut-login.png"
      />

      <img
        className="login--planet"
        alt="speechtopia login planet"
        src="/static/vectors/speechtopia-planet-login.png"
      />

      <img
        className="login--rocket"
        alt="speechtopia login rocket"
        src="/static/vectors/speechtopia-rocket-login.png"
      />
      <Typography
        className={classes.title}
        variant="h1"
        color="secondary"
        component="h1"
      >
        Error {error.code}
      </Typography>
      <Typography
        className={classes.message}
        variant="h6"
        color="textPrimary"
        component="p"
      >
        {error.message}
      </Typography>
      <Link to="/">
        <Button color="primary" variant="contained">
          {tc("actions.toHome")}
        </Button>
      </Link>
    </div>
  );
};

export default React.memo(Error);

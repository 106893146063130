import React, { useState, useEffect } from "react";
import useWindow from "../../hooks/useWindow";
const Planet = ({
  name,
  role,
  offsetX = 1,
  offsetY = 1,
  speed = 0,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { isTablet, isMobile } = useWindow();
  const [scale, setScale] = useState(isMobile ? 0.45 : isTablet ? 0.5 : 0.6);
  useEffect(() => {
    setScale(isMobile ? 0.45 : isTablet ? 0.5 : 0.65);
  }, [isMobile, isTablet]);
  return (
    <img
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-role={role}
      className={`intro__solar__planet intro__solar__planet--${name}`}
      style={{
        transform: `translate(${offsetX * speed}px, ${
          offsetY * speed
        }px) scale(${name === "7" ? 1 : scale})`, //For planet 7 (Looking like sun, we scale it up, while others are smaller)
      }}
      alt={`speechtopia planet ${name}"`}
      src={`/static/planets/Asset ${name}.png`}
    />
  );
};

export default React.memo(Planet);

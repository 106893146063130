import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import i18next from "./i18n";
import { I18nextProvider } from "react-i18next";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./scripts/app";
import "./stylesheets/main.scss";

ReactGA.initialize("UA-172913645-1", {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

process.env.NODE_ENV !== "development" &&
  Sentry.init({
    dsn:
      "https://b12f902d03af40ceb2c5fbfa22bfc4b2@o1184010.ingest.sentry.io/6301494",
  });

// function sendPerformanceMetrics(list) {
//   list.getEntries().forEach((entry) => {
//     ReactGA.timing({
//       category: "Load Performance",
//       variable: "Server Latency",
//       value: entry.responseStart - entry.requestStart,
//     });
//     ReactGA.timing({
//       category: "Load Performance",
//       variable: "Download Time",
//       value: entry.responseEnd - entry.responseStart,
//     });
//     ReactGA.timing({
//       category: "Load Performance",
//       variable: "Total Load Time",
//       value: entry.responseEnd - entry.requestStart,
//     });
//   });
// }

// var performanceObserver = new PerformanceObserver(sendPerformanceMetrics);
// performanceObserver.observe({ entryTypes: ["navigation"] });

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();

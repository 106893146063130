import React from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
  Slider,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  inputRoot: {
    "& > div": {
      width: "100%",
    },
  },
  switch: {
    gridColumn: "1/3",
    display: "flex",
    flexFlow: "column nowrap",
    textAlign: "center",
    alignItems: "center",
  },
  phoneInput: {
    flexGrow: "1",
    "& > div": {
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "0",
    },
  },
  select: {
    "& > div": {
      minWidth: "6rem",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
});

const WelcomeStudent = ({
  role,
  step,
  handleChange,
  data,
  checkData,
  birthDate,
  handleBirthDate,
  handleCheckData,
  phoneNums,
  handlePhoneNumber,
  setStage,
  stage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");

  function valuetext(value) {
    return `${value}`;
  }

  const { isSpeechtopian } = checkData;
  return (
    <React.Fragment>
      {step === 1 && role === "student" && (
        <h3 className="welcome__operations__forms__form--title">
          {t("welcome.common.registeringAs", {
            role: tc("roles.student"),
          })}
        </h3>
      )}
      <div
        className={`welcome__operations__forms__form${
          step === 1 && role === "student" ? "" : "--hidden"
        }`}
      >
        <TextField
          label={t("forms.englishName")}
          name="enName"
          required
          type="text"
          size="small"
          autoComplete="off"
          variant="standard"
          className={classes.inputRoot}
          onChange={handleChange}
          value={data.enName}
        />
        <TextField
          label={t("forms.chineseName")}
          name="cnName"
          type="text"
          size="small"
          lang="zh-Hans"
          variant="standard"
          autoComplete="off"
          className={classes.inputRoot}
          onChange={handleChange}
          value={data.cnName}
        />
        <FormControl
          size="small"
          variant="standard"
          className={classes.inputRoot}
        >
          <InputLabel id="gender-label">{t("forms.gender")}</InputLabel>
          <Select
            labelId="gender-label"
            native
            onChange={handleChange}
            value={data.sex}
            name="sex"
          >
            <option value="female">{t("forms.female")}</option>
            <option value="male">{t("forms.male")}</option>
          </Select>
        </FormControl>
        <KeyboardDatePicker
          disableFuture
          inputvariant="standard"
          name="birthDate"
          size="small"
          label={t("forms.birthDate")}
          value={birthDate}
          onChange={handleBirthDate}
          placeholder="01/01/2020"
          minDate="01/01/1980"
          className={classes.inputRoot}
          format="MM/dd/yyyy"
        />
        <div
          style={{ display: "flex", flexFlow: "row nowrap", gridColumn: "1/3" }}
        >
          <FormControl
            className={classes.select}
            size="small"
            variant="standard"
          >
            <InputLabel htmlFor="country-code-1">
              {t("common.countryPhoneCode")}
            </InputLabel>
            <Select
              native
              value={data.countryCode1}
              onChange={handleChange}
              label="Country Code"
              inputProps={{
                name: "countryCode",
                id: "country-code-1",
              }}
            >
              <option value={86} defaultValue>
                +86
              </option>
              <option value={1}>+1</option>
            </Select>
          </FormControl>
          <TextField
            label={t("common.phoneNumber")}
            name="tell"
            type="tel"
            size="small"
            autoComplete="off"
            variant="standard"
            onChange={handlePhoneNumber}
            value={phoneNums.tell}
            className={classes.phoneInput}
          />
        </div>
        <div className={classes.switch}>
          <FormControlLabel
            label={t("forms.imASpeechtopian")}
            control={
              <Switch
                color="primary"
                name="isSpeechtopian"
                value={isSpeechtopian}
                checked={isSpeechtopian}
                onChange={handleCheckData}
              />
            }
          />
          <Typography variant="caption" color="textSecondary">
            {t("forms.isSpeechtopian")}
          </Typography>
        </div>
        {isSpeechtopian && (
          <TextField
            label={t("forms.coursesEmail")}
            name="edxEmail"
            type="email"
            required
            size="small"
            autoComplete="off"
            variant="standard"
            className={classes.inputRoot}
            onChange={handleChange}
            value={data.edxEmail}
          />
        )}
        {isSpeechtopian && (
          <TextField
            label={t("forms.coursesUsername")}
            name="edxUsername"
            type="text"
            required
            size="small"
            autoComplete="off"
            variant="standard"
            className={classes.inputRoot}
            onChange={handleChange}
            value={data.edxUsername}
          />
        )}
        {isSpeechtopian && (
          <div className="welcome__operations__forms__form--stage">
            <Typography id="discrete-slider" gutterBottom>
              {tc("titles.stage")}
            </Typography>
            <Slider
              name="stage"
              value={stage}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              marks
              onChange={(event, newValue) => setStage(newValue)}
              step={1}
              min={1}
              max={9}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(WelcomeStudent);

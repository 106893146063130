import React, { useState, useContext,useEffect } from "react";
 
import AlertContext from "../../contexts/alertContext";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Button,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { PhoneIphoneOutlined, EmailOutlined } from "@material-ui/icons";
import useWindow from "../../hooks/useWindow";
import api from "../../api/apiService";
import useInput from "../../hooks/useInput";
function WechatID() {
  return (
    <svg height="25.19" width="25.19" viewBox="-51.45 -69.25 445.9 415.5">
      <g fill="#eee">
        <path d="M274 167c-7.778 0-14-6.222-14-14s6.222-14 14-14 14 6.222 14 14c0 7.389-6.222 14-14 14m-69 0c-7.778 0-14-6.222-14-14s6.222-14 14-14 14 6.222 14 14c0 7.389-6.222 14-14 14m102.39 78.581C329.216 229.871 343 206.5 343 180.827 343 133.316 297.052 95 240 95s-103 38.316-103 85.827c0 47.512 45.948 85.828 103 85.828 11.87 0 22.974-1.533 33.695-4.598.766-.383 1.915-.383 3.063-.383 1.915 0 3.83.766 5.361 1.532l22.591 13.028c.766.383 1.149.766 1.915.766a3.433 3.433 0 003.446-3.448c0-.767-.383-1.533-.383-2.683 0-.383-3.063-10.728-4.595-17.242-.383-.766-.383-1.532-.383-2.299-.383-2.682.766-4.597 2.68-5.747" />
        <path d="M164 86c-8.93 0-16-7.07-16-16s7.07-16 16-16 16 7.07 16 16c0 8.558-7.07 16-16 16m-82 0c-8.93 0-16-7.07-16-16s7.07-16 16-16 16 7.07 16 16c0 8.558-7.07 16-16 16m41.96-86C55.646 0 0 45.895 0 102.88c0 30.98 16.502 58.899 42.983 77.64 1.919 1.53 3.454 3.824 3.454 6.884 0 .764-.384 1.912-.384 2.677-1.919 7.649-5.373 20.27-5.757 20.652-.383 1.148-.767 1.913-.767 3.06 0 2.295 1.919 4.207 4.221 4.207.768 0 1.535-.382 2.303-.765l27.248-15.68c1.919-1.148 4.222-1.913 6.524-1.913 1.152 0 2.303 0 3.454.383 12.665 3.442 26.48 5.736 40.297 5.736h6.908c-2.687-8.031-4.222-16.445-4.222-25.242 0-51.631 50.658-93.701 112.83-93.701H246C237.173 37.48 185.747 0 123.96 0" />
      </g>
    </svg>
  );
}


const useStyles = makeStyles({
  title: {
    width: "100%",
    fontWeight: "normal",
    fontSize: "1.3rem",
    textTransform: "uppercase",
  },
  value: {
    // width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  wechatCode: {
    width: "50%",
    margin: "0.5rem auto",
    marginBottom: "2rem",
  },
});

const ContactUs = () => {
  const { setAlert } = useContext(AlertContext);
  const { t: ta } = useTranslation("auth");
  const { t: tc } = useTranslation("common");
  const { isMobile } = useWindow();
  const classes = useStyles();
  const [submitted,setSubmit] =useState(false);
const [subject, setSubject] = useState("-- Select Subject --");
const [inputData, handleChange] = useInput({
  subject: "",
  name: "",
  message: "",
  email: "",
});
 

 
  useEffect(()=>{
   
    setSubject("Enroll Trial Class");
  
})

const subjects = [
  { type: "Enroll Trial Class", color: "#086485" },
  { type: "Partnership Questions", color: "#8a0707" },
  { type: "Parents Comunication", color: "#9c941e" },
  { type: "Join our Team", color: "#00a68d" },
  { type: "General Quesitons", color: "#00a68d" },
];



  async function handleContactUs(event) {
    event.preventDefault();
    setSubmit(true);
    const serverResponse = await api.post("/contact_us_email/", {
      ...inputData,
      subject,
    });
    if (serverResponse) {
      const { error, data } = serverResponse;

      setAlert({ error, message: data.response });
        
    }
 
  }

  return (
    <div className="login">
      <img
        className="login--astronaut"
        alt="speechtopia login astronaut"
        src="/static/vectors/speechtopia-astronaut-login.png"
      />
      {!isMobile && (
        <img
          className="login--planet"
          alt="speechtopia login planet"
          src="/static/vectors/speechtopia-planet-login.png"
        />
      )}
      <img
        className="login--rocket"
        alt="speechtopia login rocket"
        src="/static/vectors/speechtopia-rocket-login.png"
      />

      {/* <div className="login__form" style={{ justifyContent: "center" }}>
        <img
          width="80%"
          alt="Speechtopia Wechat QR Code"
          className={classes.wechatCode}
          src="https://speechtopia-static-assets.s3-ap-southeast-1.amazonaws.com/1618129992.jpg"
        />
        <Box display="flex" flexWrap="no-wrap" justifyContent="space-between">
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <PhoneIphoneOutlined />
            <h1 className={classes.title}>{ta("common.phoneNumber")}</h1>
          </Box>
          <span className={classes.value}>1226-336-3288</span>
        </Box>

        <Divider />
        <Box display="flex" flexWrap="no-wrap" justifyContent="space-between">
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <EmailOutlined />
            <h1 className={classes.title}>{ta("common.email")}</h1>
            <span className={classes.value}>speechtopia2020@gmail.com</span>
          </Box>
        </Box>

        <Divider />
        <Box display="flex" flexWrap="no-wrap" justifyContent="space-between">
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <WechatID />
            <h1 className={classes.title}>{tc("titles.contact")}</h1>
          </Box>
          <span className={classes.value}>speechtopia-ca</span>
        </Box>

        <Divider />
        <Box display="flex" flexWrap="no-wrap" justifyContent="space-between">
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <WechatID />
            <h1 className={classes.title}>{tc("titles.sales")}</h1>
          </Box>
          <span className={classes.value}>speechtopia001</span>
        </Box>

        <Divider />
        <Box display="flex" flexWrap="no-wrap" justifyContent="space-between">
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <WechatID />
            <h1 className={classes.title}>{tc("titles.services")}</h1>
          </Box>
          <span className={classes.value}>speechtopia</span>
        </Box>
      </div> */}
      <React.Fragment>
        <Typography
          component="h1"
          variant="h5"
          style={{ "margin-bottom": "20px" }}
        >
          Contact us via Email
        </Typography>

        <form onSubmit={handleContactUs} className={classes.form}>
          <Box display="flex" style={{ "margin-bottom": "20px" }}>
            <TextField
              label="Your Name"
              name="name"
              type="text"
              required
              fullWidth={false}
              size="small"
              variant="standard"
              classes={{ root: classes.input }}
              onChange={handleChange}
            />
            <TextField
              label="Your Email"
              name="email"
              type="text"
              required
              fullWidth
              size="small"
              variant="standard"
              classes={{ root: classes.input }}
              onChange={handleChange}
            />
          </Box>
          <Box display="flex">
            <FormControl
              className={classes.select}
              size="small"
              fullWidth
              variant="standard"
            >
              <InputLabel
                id="subject"
                style={{ "font-size": "1rem" }}
                htmlFor="subject"
              >
                Subject:
              </InputLabel>
              <Select
                labelId="subject"
                fullWidth
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                label="Subject"
                inputProps={{
                  name: "subject",
                  id: "subject",
                }}
              >
                {subjects.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{ padding: "1rem" }}
                      value={item.type}
                    >
                      {item.type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <TextField
            fullWidth
            required
            multiline
            label="Your Message"
            name="message"
            type="text"
            size="small"
            variant="standard"
            rows={3}
            maxRows={3}
            classes={{ root: classes.input }}
            onChange={handleChange}
          />

          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
            disabled={submitted}
            style={{ margin: "0 auto", "margin-top": "2rem", display: "block" }}
          >
            Send Message
          </Button>
        </form>
        <Divider variant="middle" className={classes.divider} />
      </React.Fragment>
    </div>
  );
};

export default React.memo(ContactUs);

import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Button,
  // Checkbox,
  Tabs,
  AppBar,
  Tab,
  FormControl,
  InputLabel,
  Select,
  // FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
  Box,
} from "@material-ui/core";
import {
  PhoneIphoneOutlined,
  AlternateEmailOutlined,
} from "@material-ui/icons";
import { extractUrlParameter } from "../../util/strings";
import { login as DoLogin, loginSMS, getAuthSMS } from "../../api/login";
import useInput from "../../hooks/useInput";
import useWindow from "../../hooks/useWindow";
import AlertContext from "../../contexts/alertContext";
import UserContext from "../../contexts/userContext";
import { isEmpty } from "../../util/objects";

const useStyles = makeStyles({
  label: {
    "& span": {
      fontSize: "0.75rem",
    },
  },
  dialogContent: {
    backgroundColor: "#170a22",
  },
  appBar: {
    width: "100%",
    boxShadow: "initial",
  },
  tab: {
    fontWeight: "bold",
  },
  verifyPhoneContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    margin: "1rem 0",
  },
  phoneInput: {
    flexGrow: 1,
    "& > div": {
      borderRadius: 0,
    },
  },
  phoneSelect: {
    maxWidth: "6.5rem",
    "& > div": {
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
  codeButton: {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  },
  modalButton: {
    padding: "0.5rem",
    fontWeight: "bold",
    margin: "1rem 0",
  },
});

const Login = () => {
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");
  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const [loginMethod, setLoginMethod] = useState(0); // 0 for email, 1 for mobile
  const [modalOpen, setModalOpen] = useState(false); // Modal opens when logging in with via sms (FIRST TIME USERS ONLY)
  const [allOptions, setAllOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextUrl, setNextUrl] = useState("/dashboard");
  const [data, handleChange] = useInput({
    phone: "",
    smsCode: "",
    email: "",
    password: "",
    countryCode: "+86",
  });

  const [time, setTime] = useState(0); // timing for resending SMS code (resets to 60 after login attempt)
  // const [rememberMe, setRemember] = useState(true);
  const { isMobile } = useWindow();
  const classes = useStyles();

  useEffect(() => {
    //Checks for URL parameters.
    // State is for the times user is redirected because he wants to connect phone number to email
    // Next is for any next page user should be redirected to (sent from backend)
    const nextPage = extractUrlParameter("next");
    const emailState = extractUrlParameter("state");
    setNextUrl(nextPage || nextUrl);
    setAllOptions(!emailState);
  }, []);

  useEffect(() => {
    // Countdown for resend sms button on SMS login
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  async function handleVerifyPhone(event) {
    event.preventDefault();
    setLoading(true);
    if (data.phone.length < 5) {
      setAlert({ message: t("common.enterValidPhone"), error: true });
      setLoading(false);
      return;
    }
    const serverResponse = await getAuthSMS(
      data.countryCode + data.phone
    ).catch(() => {
      setAlert({ message: tc("alerts.internalServerError"), error: true });
    });
    if (serverResponse) {
      const { response, error } = serverResponse;
      setAlert({ message: response, error });
      if (!error) setTime(60);
    }
    setLoading(false);
  }

  async function handleLogin({ event = false, inputData, asyncCall }) {
    // Two ways of logging in are available, the asyncCall (given through button submissions)
    // Determines which one to be called
    if (event) event.preventDefault();
    setLoading(true);
    const serverResponse = await asyncCall({ ...inputData }).catch((error) => {
      console.error("An error occurred during asyncCall:", error);
      setAlert({ message: tc("alerts.internalServerError"), error: true });
    });
    if (serverResponse) {
      const { error, response, code } = serverResponse;
      if (error) {
        ReactGA.event({
          category: "User",
          action: "Login",
          label: "Failed",
          transport: "beacon",
        });
        setAlert({ message: response, error });
        setLoading(false);
      } else {
        ReactGA.event({
          category: "User",
          action: "Login",
          label: "Success",
          transport: "beacon",
        });
        // If the code is 449, that means user's phone number is NOT FOUND in database
        // So we ask if he has an account or wants to connect an already existing account
        if (code === 449) setModalOpen(true);
        else window.location.replace(nextUrl);
      }
    }
  }

  return (
    <div className="login">
      <img
        className="login--astronaut"
        alt="speechtopia login astronaut"
        src="/static/vectors/speechtopia-astronaut-login.png"
      />
      {!isMobile && (
        <img
          className="login--planet"
          alt="speechtopia login planet"
          src="/static/vectors/speechtopia-planet-login.png"
        />
      )}
      <img
        className="login--rocket"
        alt="speechtopia login rocket"
        src="/static/vectors/speechtopia-rocket-login.png"
      />
      <form
        className="login__form"
        onSubmit={(event) => {
          handleLogin({
            event,
            inputData: { ...data, rememberMe: true },
            asyncCall: loginMethod === 0 ? DoLogin : loginSMS,
          });
        }}
      >
        <h1>{t("common.speechtopiaPlanet")}</h1>
        <AppBar
          className={classes.appBar}
          position="static"
          color="transparent"
        >
          {allOptions && (
            <Tabs
              value={loginMethod}
              onChange={(event, newValue) => setLoginMethod(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="login options"
            >
              <Tab
                value={0}
                className={classes.tab}
                wrapped
                icon={<AlternateEmailOutlined fontSize="small" />}
                label={t("login.usingEmail")}
              />
              <Tab
                value={1}
                className={classes.tab}
                wrapped
                icon={<PhoneIphoneOutlined fontSize="small" />}
                label={t("login.usingPhone")}
              />
            </Tabs>
          )}
        </AppBar>
        {loginMethod === 0 ? (
          <React.Fragment>
            <TextField
              label={t("common.emailOrUserName")}
              name="email"
              type="text"
              value={data.email}
              required
              size="small"
              variant="standard"
              onChange={handleChange}
            />
            <TextField
              label={t("common.password")}
              name="password"
              type="password"
              value={data.password}
              required
              size="small"
              variant="standard"
              onChange={handleChange}
            />
          </React.Fragment>
        ) : (
          <Box>
            <Box className={classes.verifyPhoneContainer}>
              <FormControl
                className={classes.phoneSelect}
                size="small"
                variant="standard"
              >
                <InputLabel htmlFor="country-code">Code</InputLabel>
                <Select
                  native
                  onChange={handleChange}
                  label={t("common.countryPhoneCode")}
                  inputProps={{
                    name: "countryCode",
                    id: "country-code",
                  }}
                >
                  <option value="+86" defaultValue>
                    +86
                  </option>
                  <option disabled value="+1">
                    +1
                  </option>
                </Select>
              </FormControl>
              <TextField
                label={t("common.phoneNumber")}
                name="phone"
                type="number"
                required
                size="small"
                value={data.phone}
                variant="standard"
                className={classes.phoneInput}
                inputProps={{ min: 1 }}
                onChange={(event) => {
                  if (event.target.value.length <= 11) handleChange(event);
                }}
              />
              <Button
                className={classes.codeButton}
                disabled={time !== 0 || loading}
                size="small"
                color="primary"
                variant="contained"
                onClick={handleVerifyPhone}
              >
                {time !== 0
                  ? `${t("common.resendSMSCode")} ${time}`
                  : t("common.getSMSCode")}
              </Button>
            </Box>
            <TextField
              fullWidth
              required
              value={data.smsCode}
              label={t("common.verificationCode")}
              name="smsCode"
              type="number"
              size="small"
              inputProps={{ min: 1 }}
              variant="standard"
              onChange={(event) => {
                if (event.target.value.length <= 4) handleChange(event);
              }}
            />
          </Box>
        )}
        {/* <FormControlLabel
          className={classes.label}
          control={
            <Checkbox
              onChange={(event) => setRemember(event.target.checked)}
              name="rememberMe"
              color="primary"
            />
          }
          label={t("login.rememberMe")}
        /> */}
        <Button
          disabled={loading || (loginMethod === 1 && data.smsCode.length < 4)}
          type="submit"
          variant="contained"
          color="primary"
        >
          {tc("titles.login")}
        </Button>
        <div className="login__form__details">
          <small>
            {t("login.forgotPassword")}
            <Link className="login__form__details--link" to="/forgot-password/">
              {t("login.clickHere")}
            </Link>
          </small>
          <small>
            {t("login.noAccount")}{" "}
            <Link className="login__form__details--link" to="/register/">
              {t("login.registerNow")}
            </Link>
          </small>
          {!isEmpty(user) && (
            <small>
              {t("login.emailNotConfirmed")}{" "}
              <Link className="login__form__details--link" to="/unconfirmed/">
                {t("login.confirmMailNow")}
              </Link>
            </small>
          )}
        </div>
      </form>
      <Dialog
        fullWidth
        scroll="paper"
        open={modalOpen}
        className={classes.dialogContainer}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{ style: { backgroundColor: "#170a22" } }}
      >
        <DialogTitle color="primary" id="scroll-dialog-title">
          {t("common.alreadyHaveAccount")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className={classes.dialogContent} tabIndex={-1}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              className={classes.modalButton}
              onClick={() =>
                handleLogin({
                  inputData: { ...data, rememberMe: true },
                  asyncCall: loginSMS,
                })
              }
            >
              {t("login.phoneCreateAccount")}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                window.location.replace("/login?state=email");
              }}
              className={classes.modalButton}
            >
              {t("login.phoneConnectToAccount")}
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(Login);

import api from "./apiService";

export async function getParentConnection(style) {
  const { data, status } = await api.get(`/api/get_cpsc?style=${style}`);
  return { response: data.response, error: status !== 200 };
}

export async function queryStudentList(name) {
  const { data, status } = await api.get(
    `/api/sessions/query_students?q=${name}`
  );
  return { response: data.response, error: status !== 200 };
}

export async function getStudentList() {
  const { data, status } = await api.get(
    `/api/applications/students/all-verified/`
  );
  return { response: data.response, error: status !== 200 };
}

export async function getStudentDetails(id) {
  const { data, status } = await api.post(`/sp/${id}/`);
  return { response: data.response, error: status >= 300 };
}

export async function getStudentSessionHistory(id, filter) {
  const { data, status } = await api.get(
    `/api/get-student-sessions/hot-feature/?id=${id}&filter=${filter}`
  );
  return { response: data.response, error: status !== 200 };
}

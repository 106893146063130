import React from "react";
const TeacherInfoCard = ({ data }) => {
  return (
    <div className="teacher-info-card">
      <div
        className="teacher-info-card--image"
        style={{ backgroundImage: `url(${data.image})` }}
      />
      <div className="teacher-info-card__details">
        <h2 className="teacher-info-card__details--title">{data.name}</h2>
        <p className="teacher-info-card__details--text">{data.bio}</p>
      </div>
    </div>
  );
};

export default TeacherInfoCard;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common_en from "./locales/en/common.json";
import common_zh_HANS from "./locales/zh_HANS/common.json";
import static_en from "./locales/en/static.json";
import static_zh_HANS from "./locales/zh_HANS/static.json";
import auth_en from "./locales/en/auth.json";
import auth_zh_HANS from "./locales/zh_HANS/auth.json";
import dashboard_en from "./locales/en/dashboard.json";
import dashboard_zh_HANS from "./locales/zh_HANS/dashboard.json";
import feedback_en from "./locales/en/feedback.json";
import feedback_zh_HANS from "./locales/zh_HANS/feedback.json";
import lms_en from "./locales/en/lms.json";

const langOptions = { order: ["localStorage"] };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: false,
    detection: langOptions,
    resources: {
      en: {
        common: common_en,
        static: static_en,
        auth: auth_en,
        dashboard: dashboard_en,
        feedback: feedback_en,
        lms: lms_en,
      },
      zh_HANS: {
        common: common_zh_HANS,
        static: static_zh_HANS,
        auth: auth_zh_HANS,
        dashboard: dashboard_zh_HANS,
        feedback: feedback_zh_HANS,
      },
    },
  });

export default i18n;

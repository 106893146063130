import api from "./apiService";

export async function getCaptcha() {
  const { data, status } = await api.get("/ACSeJPG+s2dv4q8beJJYINRu/");
  return { response: data.response, error: status >= 300 };
}

export async function register({ ...inputData }) {
  const { data, status } = await api.post("/register/", { ...inputData });
  return { response: data.response, error: status !== 201 };
}

export async function resendConfirmation() {
  const { data, status } = await api.post("/resend_confirmation_email/");
  return { response: data.response, error: status !== 200 };
}
export async function addStudent({ ...inputData }) {
  const { data, status } = await api.post("/api/add-user/student/", {
    ...inputData,
  });
  return { response: data.response, error: status !== 200, code: status };
}

export async function addOrganizer({ ...inputData }) {
  const { data, status } = await api.post("/api/add-user/organizer/", {
    ...inputData,
  });
  return { response: data.response, error: status !== 200, code: status };
}


export async function applyStudent({ ...inputData }) {
  const { data, status } = await api.post("/api/set-user/student/", {
    ...inputData,
  });
  return { response: data.response, error: status !== 200, code: status };
}

export async function applyJudge({ ...inputData }) {
  const { data, status } = await api.post("/api/set-user/judge/", {
    ...inputData,
  });
  return { response: data.response, error: status !== 200, code: status };
}

export async function applyParent({ ...inputData }) {
  const { data, status } = await api.post("/api/set-user/parent/", {
    ...inputData,
  });
  return { response: data.response, error: status !== 200, code: status };
}

export async function applyOrganizer({ ...inputData }) {
  const { data, status } = await api.post("/api/set-user/organiser/", {
    ...inputData,
  });
  return { response: data.response, error: status !== 200, code: status };
}

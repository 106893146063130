import React from "react";
import { useTranslation } from "react-i18next";
import MaterialTable from "material-table";
import useTableOptions from "../../hooks/useTableOptions";

const SessionHistory = ({ sessions, loading, columns, style }) => {
  const { t: td } = useTranslation("dashboard");
  const { localization, options } = useTableOptions();

  return (
    <MaterialTable
      title={td("sessions.overview.historyOfSessions")}
      isLoading={loading}
      columns={columns}
      data={sessions}
      style={style}
      localization={localization}
      options={{
        ...options,
        pageSizeOptions: [5, 10, 15],
        pageSize: 5,
      }}
    />
  );
};

export default SessionHistory;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Planet from "../../components/main/planet";
import { calculateScrollProgress } from "../../util/scroll";
import useWindow from "../../hooks/useWindow";

import { Box, Divider, Typography, makeStyles } from "@material-ui/core";

const Intro = () => {
  const [offset, setOffset] = useState(0);
  const { t } = useTranslation("static");
  const { t: tc } = useTranslation("common");
  const [title, setTitle] = useState(t("intro.header.main"));
  const { isMobile } = useWindow();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setOffset(calculateScrollProgress());
    });
  }, []);

  function handleMouseLeave() {
    setTitle(t("intro.header.main"));
  }

  function handleHover(event) {
    setTitle(event.target.dataset.role);
  }

  return (
    <section className="intro">
      <h1 className="intro--title">{title}</h1>
      <div className="intro__solar">
        <img
          src="/static/vectors/speechtopia-spaceship.png"
          alt="Speechtopia Debate Spaceship"
          className="intro__solar--spaceship"
        />
        <Planet
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-7")}
          name="7"
        />
        <Planet
          speed={0.35}
          offsetX={offset}
          offsetY={-offset}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-10")}
          name="10"
        />
        <Planet
          speed={0.1}
          offsetX={-offset}
          offsetY={offset}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-6")}
          name="6"
        />
        <Planet
          speed={0.5}
          offsetX={offset}
          offsetY={-offset}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-1")}
          name="1"
        />
        <Planet
          speed={0.8}
          offsetX={offset}
          offsetY={-offset}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-8")}
          name="8"
        />
        <Planet
          speed={1.5}
          offsetX={offset}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-9")}
          name="9"
        />
        <Planet
          speed={0.75}
          offsetX={offset}
          offsetY={-offset}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-4")}
          name="4"
        />
        <Planet
          speed={isMobile ? 0.5 : 1}
          offsetX={offset}
          offsetY={offset}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleHover}
          role={t("intro.header.planet-11")}
          name="11"
        />
        <div className="intro--overlay" />
      </div>
      <div className="intro__descriptions">
        <div>
          <h1 className="intro__descriptions--title">
            {t("static:intro.description.aboutSpeechtopia")}
          </h1>
          <p className="intro__descriptions--text">
            <Trans
              i18nKey="static:intro.description.aboutSpeechtopiaDesc"
              defaultValue="Speechtopia is a leading global platform that <bold>merges art, leadership, and communication</bold> through debate and speech education. We believe in the transformative power of <bold>persuasive discourse</bold> and <bold>critical thinking</bold>, equipping students with the skills they need to become effective communicators, empathetic leaders, and engaged global citizens. "
              components={{ bold: <strong style={{ color: "#00eaf2" }} /> }}
            />
          </p>
        </div>
        <div>
          <h1 className="intro__descriptions--title">
            {t("static:intro.description.speechModel")}
          </h1>
          <p className="intro__descriptions--text">
            <Trans
              i18nKey="static:intro.description.speechModelDesc"
              defaultValue="Our model combines rigorous training, interactive learning experiences, and creative expression. We nurture students' abilities to <bold>articulate their thoughts, analyze complex issues</bold>, and <bold>present compelling arguments</bold>. Our model fosters a collaborative and inclusive environment where students develop their own authentic voices while respecting diverse perspectives."
              components={{ bold: <strong style={{ color: "#00eaf2" }} /> }}
            />
          </p>
        </div>
      </div>

      <Typography>
        <p
          style={{
            color: "#00eaf2",
            fontSize: "1.5rem",
            "text-align": "center",
            paddingBottom: "25px",
          }}
        >
          Speechtopia Students Got Accepted Into
        </p>
      </Typography>
      <div style={{ justifyContent: "center", width: "80%", margin: "0 auto" }}>
        <Divider />
        <Box
          display="flex"
          flexWrap="no-wrap"
          style={{ "padding-top": "20px", paddingBottom: "20px" }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/brown.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/harvard.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/upenn.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/princeton.png"
              alt="Speechtopia Debate Students"
            />
          </Box>{" "}
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/ucb.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          flexWrap="no-wrap"
          style={{ "padding-top": "20px", paddingBottom: "20px" }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/umich.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/yale.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/dartmouth.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/cornell.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
          <Box
            display="flex"
            flexWrap="no-wrap"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <img
              src="/static/images/columbia.png"
              alt="Speechtopia Debate Students"
            />
          </Box>
        </Box>
        <Divider />
      </div>

      <div className="intro__links">
        <div className="intro__links__box">
          <Link to="/students">{tc("titles.students")}</Link>
        </div>
        <div className="intro__links__box">
          <Link to="/teachers">{tc("titles.teachers")}</Link>
        </div>
        <div className="intro__links__box">
          <Link to="/methodology">{tc("titles.methodology")}</Link>
        </div>
        <div className="intro__links__box">
          <Link to="/products">{tc("titles.products")}</Link>
        </div>
      </div>
      <Divider />

      <h2
        className="intro__links__box"
        style={{ textAlign: "center", width: "100%", padding: "1rem" }}
      >
        <span>
  
          <a href="/contact-us" style={{ color: "#00eaf2" }}>
            Contact us for a trial lesson
          </a>
        </span>
      </h2>
    </section>
  );
};

export default Intro;

import React, { useState } from "react";
import { makeStyles, CircularProgress, Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const useLoading = (initialState = false) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(initialState);
  function LoadingComponent({ style, ...otherProps }) {
    return (
      <Backdrop
        style={style}
        {...otherProps}
        className={classes.backdrop}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return { loading, setLoading, LoadingComponent };
};

export default useLoading;

import React, { createContext, useState } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { red, deepPurple } from "@material-ui/core/colors";

const ThemeContext = createContext();
ThemeContext.displayName = "ThemeContext";

function ThemeProvider({ children }) {
  const [isDark, setIsDark] = useState(true);
  const paletteType = isDark ? "dark" : "light";
  const backgroundColor = isDark ? "#170a22" : "#eee";

  const theme = createMuiTheme({
    palette: {
      type: paletteType,
      primary: { main: deepPurple[300] },
      info: { main: "#ffb400" },
      background: { default: backgroundColor, paper: "#332d38f2" },
    },
    typography: { fontFamily: "Montserrat" },
    // overrides: {
    //   MuiOutlinedInput: {
    //     root: {
    //       borderRadius: "2rem",
    //       backgroundColor: "#0B0A18",
    //       "& input:-webkit-autofill": {
    //         WebkitBoxShadow: "0 0 0 100px #202020 inset",
    //       },
    //     },
    //   },
    //   MuiButton: {
    //     root: {
    //       borderRadius: "2rem",
    //     },
    //   },
    // },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeContext.Provider
        value={{ setTheme: () => setIsDark(!isDark), isDark }}
      >
        {children}
      </ThemeContext.Provider>
    </MuiThemeProvider>
  );
}

export { ThemeProvider, ThemeContext };

import { useState, useEffect } from "react";
import debounce from "lodash.debounce";

const useWindow = () => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 425);
  const [isTablet, setTablet] = useState(window.innerWidth <= 768);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions());
    return () => {
      window.removeEventListener("resize", updateDimensions());
    };
  }, []);
  function updateDimensions() {
    return debounce(() => {
      setMobile(window.innerWidth <= 425);
      setTablet(window.innerWidth <= 768);
    }, 500);
  }
  return { isMobile, isTablet };
};

export default useWindow;

import React from "react";
import { NavLink } from "react-router-dom";
const LinkTo = ({ to = "/", text = "Home" }) => {
  return (
    <div className="link-to">
      <h1>
        <NavLink to={to}>{text}</NavLink>
      </h1>
    </div>
  );
};

export default LinkTo;

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { green } from "@material-ui/core/colors";
import { InfoOutlined } from "@material-ui/icons";
import {
  makeStyles,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { getCriteria } from "../util/criterias";

const heptagon =
  "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 auto",
    overflow: "initial",
    position: "relative",
    width: "20rem",
    height: "25rem",
    display: "flex",
    flexFlow: "row nowrap",
    padding: "0.25rem",
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: "0.5rem",

    [theme.breakpoints.down("sm")]: {
      width: "20rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "15rem",
      height: "20rem",
    },

    "&::before": {
      content: "''",
      zIndex: -1,
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "0.5rem",
      backgroundColor: theme.palette.info.main,
      boxShadow: `-1px -1px 1rem ${theme.palette.info.main}`,
      animation: "scale2 10s infinite ease",
    },
    // boxShadow: `0px 0px 22px ${theme.palette.info.main}`,
  },
  actionArea: {
    zIndex: 0,
    flexGrow: 1,
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    backgroundColor: "#1f2537",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem",
  },
  itemDescription: {
    padding: "0.5rem",
  },
  // type: {
  //   padding: "0.25rem",
  //   fontWeight: "bold",
  //   textAlign: "center",
  //   writingMode: "vertical-rl",
  // },
  image: {
    width: "100%",
    height: "11rem",
    marginBottom: "0.25rem",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem",
    boxShadow: "0 1rem 2.7rem #000, inset 0 1rem 2.7rem #000",
    cursor: "pointer",
    // [theme.breakpoints.down("sm")]: {
    //   height: "8rem",
    //   width: "8rem",
    // },
  },
  title: {
    width: "100%",
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    cursor: "pointer",
  },
  content: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    flexGrow: "1",
    padding: "0.25rem",
    "&:last-child": {
      paddingBottom: "0.2rem",
    },
  },
  topeImage: {
    width: "1rem",
    height: "1rem",
  },
  topeText: {
    margin: "0 0.25rem",
    fontWeight: "bold",
  },
  attribute: {
    width: "100%",
    margin: "0.25rem 0",
    textAlign: "center",

    "& > span": {
      fontWeight: "bold",
      color: green["A700"],
    },
  },
  info: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    // margin: ".25rem",
  },
  level: {
    margin: "0 0.5rem",
    textAlign: "center",
    fontWeight: "bold",
  },

  gemContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
  },
  emptySocket: {
    height: "1.15rem",
    width: "1.15rem",
    margin: "0 0.25rem",
    border: `2px solid ${theme.palette.text.secondary}`,
    boxShadow: "-0.15rem 0.14rem 0.6rem #000, inset 0.1rem 0.1rem 0.4rem #000",
    borderRadius: "100%",
    background: "transparent",
    cursor: "pointer",
    opacity: "0.8",
    transition: "all 0.3s ease",
  },
  gem: {
    border: "none",
    clipPath: heptagon,
    backgroundColor: theme.palette.info.main,
    boxShadow: "initial",
    cursor: "initial",

    "&:hover": {
      opacity: 1,
    },
  },

  activeBanner: {
    width: "4rem",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1000,
    opacity: 0.95,
    backgroundColor: theme.palette.success.main,
    textAlign: "center",
    borderTopLeftRadius: "0.5rem",
  },

  collectedBanner: {
    padding: "0 0.5rem",
    alignSelf: "center",
    opacity: 0.95,
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    border: "1px solid",
    fontWeight: "bold",
    textAlign: "center",
    borderTopRightRadius: "0.5rem",
    borderTopLeftRadius: "0.5rem",
  },
  notCollected: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
  },
}));

function ActiveBanner() {
  const classes = useStyles();
  return <div className={classes.activeBanner}>Active</div>;
}

function CollectBanner({ status }) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.collectedBanner, !status && classes.notCollected)}
    >
      {status ? "Collected" : "Not Collected"}
    </div>
  );
}

const ChallengeCard = ({
  CCId,
  title = "sample title",
  active = true,
  image,
  level = 5,
  lvl1Description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, voluptatibus?",
  lvl2Description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, voluptatibus?",
  lvl3Description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, voluptatibus?",
  lvl4Description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, voluptatibus?",
  lvl5Description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, voluptatibus?",
  socket1,
  socket2,
  socket3,
  socket4,
  socket5,
  primaryStat,
  collected = false,
  secondaryStat,
  onGemClick = () => {},
  onCardClick = () => {},
  style,
}) => {
  const classes = useStyles();

  const [gems, setGems] = useState([
    { socket: socket1 },
    { socket: socket2 },
    { socket: socket3 },
    { socket: socket4 },
    { socket: socket5 },
  ]);

  useEffect(() => {
    setGems([
      { socket: socket1 },
      { socket: socket2 },
      { socket: socket3 },
      { socket: socket4 },
      { socket: socket5 },
    ]);
  }, [socket1, socket2, socket3, socket4, socket5, CCId]);

  return (
    <Card style={style} className={classes.container}>
      {active && <ActiveBanner />}

      <div className={classes.actionArea}>
        <CardMedia
          onClick={onCardClick}
          className={classes.image}
          image={image}
          title={title}
        />
        <CardContent className={classes.content}>
          <Typography
            onClick={onCardClick}
            className={classes.title}
            gutterBottom
            variant="body1"
            component="h2"
          >
            {title}
          </Typography>
          <CollectBanner status={collected} />
          <Divider />
          <Typography
            className={classes.attribute}
            component="p"
            variant="body1"
          >
            {getCriteria(primaryStat)?.name}{" "}
            <Typography variant="body1" component="span">
              +{level}
            </Typography>
          </Typography>

          <Typography
            className={classes.attribute}
            component="p"
            variant="body1"
          >
            {getCriteria(secondaryStat)?.name}{" "}
            <Typography variant="body1" component="span">
              +{level}
            </Typography>
          </Typography>

          <Typography
            className={classes.itemDescription}
            align="left"
            component="p"
            variant="caption"
          >
            {lvl1Description}
          </Typography>
          <div className={classes.info}>
            <Typography
              component="span"
              className={classes.level}
              variant="caption"
            >
              LVL.{level}
            </Typography>
            <Tooltip
              title={
                <Box p="2">
                  <Typography variant="h6" color="inherit">
                    Level Descriptions
                  </Typography>
                  <br />
                  <ul>
                    <Typography variant="subtitle2">
                      LVL.2: {lvl2Description}
                    </Typography>
                    <br />
                    <Typography variant="subtitle2">
                      LVL.3: {lvl3Description}
                    </Typography>
                    <br />
                    <Typography variant="subtitle2">
                      LVL.4: {lvl4Description}
                    </Typography>
                    <br />
                    <Typography variant="subtitle2">
                      LVL.:5 {lvl5Description}
                    </Typography>
                  </ul>
                </Box>
              }
            >
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          </div>

          <div className={classes.gemContainer}>
            {gems.map((gem, index) => (
              <span
                key={index}
                onClick={() =>
                  gem.socket === null &&
                  onGemClick({ index, cardTitle: title, CCId })
                }
                className={clsx(
                  classes.emptySocket,
                  gem.socket !== null && classes.gem
                )}
              />
            ))}
          </div>
        </CardContent>
      </div>
    </Card>
  );
};

export default ChallengeCard;

import React, { useState, useEffect } from "react";
import { makeStyles, Snackbar, Typography } from "@material-ui/core";
import { socket } from "../../api/socket";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
  root: {
    "& > div": {
      backgroundColor: "inherit",
    },
  },
  announcement: {
    margin: "1rem",
  },
  notificationContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    fontWeight: "bold",
    marginBottom: "0.25rem",
  },
  notificationMessage: {
    padding: "0",
  },
}));

const Notification = () => {
  const classes = useStyles();
  const [notification, setNotification] = useState({
    color: "",
    title: "",
    message: "",
  });
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    socket.connect();
    socket.emit("join_notification_channel");
    socket.on("receive_notification", (data) => {
      setNotification(data);
      setAlertOpen(true);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Snackbar
      open={alertOpen}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={1000000}
      onClose={() => setAlertOpen(false)}
      style={{ backgroundColor: notification.color }}
      classes={{ root: classes.root }}
    >
      <MuiAlert
        classes={{ message: classes.notificationMessage }}
        elevation={6}
        variant="filled"
        onClose={() => setAlertOpen(false)}
        severity="info"
      >
        <span className={classes.notificationContainer}>
          <Typography
            color="textPrimary"
            className={classes.notificationContainer}
            variant="h6"
          >
            {notification.title}
          </Typography>
          <Typography color="textPrimary" variant="body2">
            {notification.message}
          </Typography>
        </span>
      </MuiAlert>
    </Snackbar>
  );
};

export default Notification;

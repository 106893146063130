import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga";
import { useTranslation, Trans } from "react-i18next";
import {
  InputLabel,
  FormControl,
  Select,
  Button,
  StepLabel,
  Typography,
  Step,
  Stepper,
  makeStyles,
} from "@material-ui/core";
import {
  applyStudent,
  applyJudge,
  applyOrganizer,
  applyParent,
} from "../../api/register";
import WelcomeStudent from "../../components/dashboard/welcomeStudent";
import WelcomeParent from "../../components/dashboard/welcomeParent";
import WelcomeOrganizer from "../../components/dashboard/welcomeOrganizer";
import WelcomeJudge from "../../components/dashboard/welcomeJudge";
import UserContext from "../../contexts/userContext";
import AlertContext from "../../contexts/alertContext";
import useInput from "../../hooks/useInput";
import useCheck from "../../hooks/useCheck";

const useStyles = makeStyles(() => ({
  stepContainer: {
    backgroundColor: "transparent",
  },
  title: {
    marginBottom: "5rem",
    textAlign: "center",
  },
  paragraph: {
    lineHeight: "2rem",
  },
  subtitle: {
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  roleLabel: {
    backgroundColor: "#0B0A18",
    borderRadius: "100%",
  },
  roleRoot: {
    width: "10rem",
    justifySelf: "center",
  },
}));

const Welcome = () => {
  //Welcome is the parent for all other welcome forms.
  //All data will be controlled from here and is shared among all forms (parents, students, judges and organizers)
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");
  const { user, updateUser } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);
  const classes = useStyles();
  const steps = getSteps();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(t("welcome.common.submitted"));
  const [activeStep, setActiveStep] = useState(0);
  const [role, setRole] = useState("student");
  const [roleText, setRoleText] = useState("");
  const [stage, setStage] = useState(1); // Stage event handler is different from UseInput Hook, so a separate state is used for it
  const [checkData, handleCheckData] = useCheck({ isSpeechtopian: false });
  const [phoneNums, setPhoneNums] = useState({ tell: "", tell2: "" });
  const [birthDate, setBirthDate] = useState(new Date());

  const [data, handleChange] = useInput({
    firstName: "",
    lastName: "",
    name: "",
    cnName: "",
    enName: "",
    edxEmail: "",
    edxUsername: "",
    country: "China",
    city: "",
    state: "",
    street: "",
    sex: "female",
    countryCode: "+86",
    countryCode2: "+86",
  });

  function handlePhoneNumber(event) {
    if (event.target.value.length <= 11) {
      setPhoneNums({
        ...phoneNums,
        [event.target.name]: event.currentTarget.value,
      });
    }
  }

  function pickRole(input) {
    var text = "";
    switch (input) {
      case "parent":
        text = t("welcome.parentWelcome");
        break;
      case "student":
        text = " ";
        break;
      case "organizer":
        text = t("welcome.organizerWelcome");
        break;
      case "judge":
        text = t("welcome.judgeWelcome");
        break;
      default:
        text = t("welcome.roleDoesNotExis");
    }
    setRole(input);
    setRoleText(text);
  }

  function getSteps() {
    //Changing text on stepper on top of the page based on the ActiveStep
    return [t("welcome.steps.1"), t("welcome.steps.2"), t("welcome.steps.3")];
  }

  useEffect(() => {
    if (user.status === "P") {
      setActiveStep(2);
    }
    if (user.status === "V") {
      setActiveStep(2);
      setMessage(t("welcome.common.alreadyVerified"));
    }
    // if (user.status === "D") {
    //   setActiveStep(2);
    //   setMessage(t("welcome.common.declined"));
    // }
    else setActiveStep(0);
  }, [user, t]);

  async function handleSubmit(event) {
    //There's only one submit method for all forms, based on the role, different api calls are made
    //data is shared among all these forms. So some extra data will be sent to server but it will not be used by backend, it's just there!
    event.preventDefault();
    setLoading(true);
    const dataToSend = { ...data };
    dataToSend.tell = dataToSend.countryCode + phoneNums.tell;
    dataToSend.tell2 = dataToSend.countryCode2 + phoneNums.tell2;
    dataToSend.isSpeechtopian = checkData.isSpeechtopian;
    dataToSend.stage = stage;
    dataToSend.birthDate = birthDate;
    var serverResponse;
    switch (role) {
      case "student":
        serverResponse = await applyStudent(dataToSend).catch(() => {
          setAlert({ message: tc("alerts.internalServerError"), error: true });
          ReactGA.event({
            category: "User",
            action: "Apply",
            label: "Failed",
            transport: "beacon",
          });
        });
        break;
      case "judge":
        serverResponse = await applyJudge(dataToSend).catch(() => {
          setAlert({ message: tc("alerts.internalServerError"), error: true });
          ReactGA.event({
            category: "User",
            action: "Apply",
            label: "Failed",
            transport: "beacon",
          });
        });
        break;
      case "parent":
        serverResponse = await applyParent(dataToSend).catch(() => {
          setAlert({ message: tc("alerts.internalServerError"), error: true });
          ReactGA.event({
            category: "User",
            action: "Apply",
            label: "Failed",
            transport: "beacon",
          });
        });
        break;
      case "organizer":
        serverResponse = await applyOrganizer(dataToSend).catch(() => {
          setAlert({ message: tc("alerts.internalServerError"), error: true });
          ReactGA.event({
            category: "User",
            action: "Apply",
            label: "Failed",
            transport: "beacon",
          });
        });
        break;
      default:
        break;
    }
    if (serverResponse) {
      const { error, code, response } = serverResponse;
      if (code === 409 || (code === 200 && !error)) {
        setActiveStep(activeStep + 1);
        ReactGA.event({
          category: "User",
          action: "Apply",
          label: "Success",
          transport: "beacon",
        });
        if (code === 409) setMessage("welcome.common.alreadyVerified");
      } else if (code === 403) setAlert({ message: response, error });

      if (error)
        ReactGA.event({
          category: "User",
          action: "Apply",
          label: "Failed",
          transport: "beacon",
        });
    }
    setLoading(false);
  }

  return (
    <div className="welcome">
      <div className="welcome__description">
        <div>
          <Typography className={classes.title} variant="h3">
            {t("welcome.common.title")}:
          </Typography>
          {activeStep === 0 ? (
            <React.Fragment>
              <Typography className={classes.paragraph} variant="body1">
                {t("welcome.common.description")}
              </Typography>
              <ul>
                <li>{tc("roles.student")}</li>
                <li>{tc("roles.parent")}</li>
                <li>{tc("roles.organizer")}</li>
                <li>{tc("roles.judge")}</li>
              </ul>
              <Typography className={classes.paragraph} variant="body1">
                {t("welcome.common.pickARoleDescription")}
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography className={classes.subtitle} variant="h5">{`${t(
                "welcome.common.registeringAs"
              )} ${role}`}</Typography>
              <Typography className={classes.paragraph} variant="body1">
                {roleText}
              </Typography>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="welcome__operations">
        <div className="welcome__operations__steps">
          <Stepper
            className={classes.stepContainer}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="welcome__operations__forms">
          {activeStep === 0 && (
            <FormControl className={classes.roleRoot} variant="standard">
              <InputLabel className={classes.roleLabel} id="role-label">
                {tc("titles.role")}
              </InputLabel>
              <Select
                labelId="role-label"
                native
                value={role}
                onChange={(event) => pickRole(event.target.value)}
                name="role"
              >
                <option value="student">{tc("roles.student")}</option>
                <option value="parent">{tc("roles.parent")}</option>
                <option value="organizer">{tc("roles.organizer")}</option>
                <option value="judge">{tc("roles.judge")}</option>
              </Select>
            </FormControl>
          )}
          {activeStep !== steps.length - 1 && (
            <form
              onSubmit={handleSubmit}
              className="welcome__operations__forms--container"
            >
              {role === "student" && (
                //All Welcome* components are just div containers that render based on the chosen role in activeStep = 1.
                //The form Tag above handles the submission of them all with the help of buttons down below.
                <WelcomeStudent
                  setStage={(stage) => setStage(stage)}
                  stage={stage}
                  handleChange={handleChange}
                  data={data}
                  birthDate={birthDate}
                  handleBirthDate={setBirthDate}
                  checkData={checkData}
                  handleCheckData={handleCheckData}
                  handlePhoneNumber={handlePhoneNumber}
                  phoneNums={phoneNums}
                  step={activeStep}
                  role={role}
                />
              )}
              {role === "parent" && (
                <WelcomeParent
                  handleChange={handleChange}
                  data={data}
                  birthDate={birthDate}
                  handleBirthDate={setBirthDate}
                  handlePhoneNumber={handlePhoneNumber}
                  phoneNums={phoneNums}
                  step={activeStep}
                  role={role}
                />
              )}
              {role === "organizer" && (
                <WelcomeOrganizer
                  handleChange={handleChange}
                  birthDate={birthDate}
                  handleBirthDate={setBirthDate}
                  data={data}
                  step={activeStep}
                  role={role}
                />
              )}
              {role === "judge" && (
                <WelcomeJudge
                  handleChange={handleChange}
                  birthDate={birthDate}
                  handleBirthDate={setBirthDate}
                  data={data}
                  step={activeStep}
                  role={role}
                />
              )}
              <div className="welcome__operations__button-group">
                {activeStep !== steps.length - 1 && (
                  <React.Fragment>
                    <Button
                      type="button"
                      disabled={activeStep === 0}
                      onClick={() => setActiveStep(activeStep - 1)}
                    >
                      {tc(`actions.back`)}
                    </Button>

                    {activeStep === 1 ? (
                      // Button is used for navigation except for when it reaches the forms (when activeStep = 1), then it acts as submission
                      <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {tc(`actions.submit`)}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => setActiveStep(activeStep + 1)}
                      >
                        {tc(`actions.next`)}
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </div>
            </form>
          )}
          {activeStep === 2 && (
            <div className="welcome__operations__finish">
              <h1>{message}</h1>
              <p>
                {/* Based on user's account status, we must show different texts in the last step */}
                {user.status === "P" && t("welcome.common.submitted")}
                {user.status === "V" && (
                  <Typography>
                    {t("welcome.common.verifiedMessage")}{" "}
                    <Typography component="strong" color="primary">
                      {user.email}
                    </Typography>
                  </Typography>
                )}
                {/* {user.status === "D" && t("welcome.common.declined")} */}
              </p>
              <br />
              {/* In case user's account has declined, they should be directed to Home page */}
              {user.status !== "D" && (
                <Button
                  onClick={() => {
                    updateUser();
                    window.location.replace("/dashboard");
                  }}
                  variant="contained"
                  color="primary"
                >
                  {tc("actions.toDashboard")}
                </Button>
              )}
              {user.status === "D" && (
                <Button
                  onClick={() => setActiveStep(0)}
                  variant="contained"
                  color="primary"
                >
                  {tc("actions.applyAgain")}
                </Button>
              )}
            </div>
          )}
        </div>
        <span className="welcome__operations__contact">
          <Trans
            i18nKey="auth:welcome.common.contactInformation"
            values={{ email: "support@speechtopia.club" }}
            components={{ span: <span /> }}
            defaultValue="Contact us at <span>{{email}}</span>"
          />
        </span>
      </div>
    </div>
  );
};

export default React.memo(Welcome);

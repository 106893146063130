import React from "react";
import { useTranslation } from "react-i18next";

import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  makeStyles,
} from "@material-ui/core";
import countries from "../../util/countries";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  countryOption: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  inputRoot: {
    "& > div": {
      width: "100%",
    },
  },
  stageText: {
    textAlign: "center",
  },
  phoneInput: {
    flexGrow: "1",
    "& > div": {
      minWidth: "6rem",
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "0",
    },
  },
  select: {
    "& > div": {
      minWidth: "6rem",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
});
const WelcomeParent = ({
  role,
  step,
  handleChange,
  data,
  phoneNums,
  birthDate,
  handleBirthDate,
  handlePhoneNumber,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("auth");
  const { t: tc } = useTranslation("common");

  return (
    <div
      className={`welcome__operations__forms__form${
        step === 1 && role === "parent" ? "" : "--hidden"
      }`}
    >
      <h3>
        {t("welcome.common.registeringAs", {
          role: tc("roles.parent"),
        })}
      </h3>
      <TextField
        label={t("forms.fullName")}
        name="name"
        type="text"
        size="small"
        autoComplete="off"
        variant="standard"
        value={data.name}
        onChange={handleChange}
        className={classes.inputRoot}
      />
      <KeyboardDatePicker
        disableFuture
        inputvariant="standard"
        label={t("forms.birthDate")}
        size="small"
        value={birthDate}
        onChange={handleBirthDate}
        minDate="01/01/1980"
        className={classes.inputRoot}
        format="MM/dd/yyyy"
      />
      <div style={{ display: "flex", flexFlow: "row nowrap" }}>
        <FormControl className={classes.select} size="small" variant="standard">
          <InputLabel htmlFor="country-code-1">
            {t("common.countryPhoneCode")}
          </InputLabel>
          <Select
            native
            value={data.countryCode1}
            onChange={handleChange}
            label="Country Code"
            inputProps={{
              name: "countryCode",
              id: "country-code-1",
            }}
          >
            <option value={86} defaultValue>
              +86
            </option>
            <option value={1}>+1</option>
          </Select>
        </FormControl>
        <TextField
          label={t("common.phoneNumber")}
          name="tell"
          type="tel"
          size="small"
          autoComplete="off"
          variant="standard"
          onChange={handlePhoneNumber}
          value={phoneNums.tell}
          className={classes.phoneInput}
        />
      </div>
      <div style={{ display: "flex", flexFlow: "row nowrap" }}>
        <FormControl className={classes.select} size="small" variant="standard">
          <InputLabel htmlFor="country-code-1">
            {t("common.countryPhoneCode")}
          </InputLabel>
          <Select
            native
            value={data.countryCode2}
            onChange={handleChange}
            label="Country Code"
            inputProps={{
              name: "countryCode2",
              id: "country-code-1",
            }}
          >
            <option value={86} defaultValue>
              +86
            </option>
            <option value={1}>+1</option>
          </Select>
        </FormControl>
        <TextField
          label={`${t("common.phoneNumber")} 2`}
          name="tell2"
          type="tel"
          size="small"
          autoComplete="off"
          variant="standard"
          onChange={handlePhoneNumber}
          value={phoneNums.tell2}
          className={classes.phoneInput}
        />
      </div>
      <FormControl
        size="small"
        variant="standard"
        className={classes.inputRoot}
      >
        <InputLabel id="country-label">{t("forms.country")}</InputLabel>
        <Select
          labelId="country-label"
          native
          onChange={handleChange}
          value={data.country}
          name="country"
        >
          {countries.map((country) => {
            return (
              <option key={country.code} value={country.label}>
                {country.label} ({country.code})
              </option>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        label={t("forms.city")}
        name="city"
        type="text"
        value={data.city}
        required
        size="small"
        autoComplete="off"
        variant="standard"
        className={classes.inputRoot}
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(WelcomeParent);

import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { getStudentSessionHistory } from "../../api/student";
import { getStudentDetails } from "../../api/student";
import useLoading from "../../hooks/useLoading";
import AlertContext from "../../contexts/alertContext";
import ChallengeCard from "../../elements/challengeCard";
import SessionHistory from "./sessionHistory";
import UserContext from "../../contexts/userContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexFlow: "row wrap",
    padding: "0.8rem",
    paddingTop: "4rem",
  },

  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },

  title: {
    width: "100%",
    padding: "1rem",
    marginTop: "2rem",
  },
  profileCard: {
    maxWidth: "18rem",
    padding: "1rem",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "initial",
      padding: "0",
      border: "initial",
    },
  },
  avatar: {
    width: "7.5rem",
    height: "7.5rem",
    margin: "0 auto",
    opacity: "0.9",
  },

  listItem: {
    width: "100%",
    fontSize: "0.8rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },

  statPrimary: {
    margin: "0 0.75rem",
    fontWeight: "bold",
    fontSize: "6rem",
    textAlign: "center",
  },

  statSecondary: {
    maxWidth: "8rem",
    margin: "0 auto",
    fontSize: "1.1rem",
    textAlign: "center",
  },

  ccContainer: {
    width: "100%",
    minHeight: "13rem",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    margin: "1rem 0",
  },
}));

const StudentProfile = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { user } = useContext(UserContext);
  const { t: tl } = useTranslation("lms");
  const { t: ta } = useTranslation("auth");
  const { t: tc } = useTranslation("common");
  const { t: td } = useTranslation("dashboard");
  const [student, setStudent] = useState({});
  const { LoadingComponent, setLoading } = useLoading();
  const { setAlert } = useContext(AlertContext);
  const [sessionHistory, setSessionHistory] = useState([]);

  const columns = [
    {
      title: td("sessions.terms.debateResolution"),
      field: "resolution",
      render: (rowData) => (
        <a
          className={classes.link}
          href={rowData.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {rowData.resolution}
        </a>
      ),
    },
    {
      title: td("sessions.terms.judges"),
      type: "string",
      customFilterAndSearch: (term, rowData) => {
        const judgesNames = rowData.judge.map((judge) => judge.name);
        return judgesNames.join(" ").toLowerCase().includes(term.toLowerCase());
      },
      render: ({ judge }) => {
        const judgesNames = judge.map((judge) => judge.name);
        return (
          <Typography variant="body2">{judgesNames.join(", ")}</Typography>
        );
      },
    },
    {
      title: td("sessions.terms.startDate"),
      field: "start_date",
    },
    {
      title: td("sessions.terms.debateStyle"),
      field: "type",
    },
    {
      title: td("sessions.terms.debateStatus"),
      field: "status",
      render: ({ status }) => {
        var color;
        if (status === "started") {
          color = green[700];
        } else if (status === "terminated") {
          color = "initial";
        } else if (status === "ended") color = grey[500];
        else color = "#eee";

        return (
          <Typography variant="body2" style={{ color, fontWeight: "bold" }}>
            {status}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    const urlToFetch = pathname.replace("/sp/", "").replace("/", ""); //Extracting hash for requestion correct student ID from server
    getStudentDetails(urlToFetch)
      .catch(() => {
        setAlert({ message: tc("alerts.internalServerError"), error: true });
      })
      .then(({ response, error }) => {
        if (!error) setStudent(response);
        else setAlert({ message: response, error });
        setLoading(false);
      });
  }, [pathname, setAlert, setLoading, tc]);

  useEffect(() => {
    // WARNNING: DRY VIOLATION AHEAD...
    setLoading(true);
    if (student.user_id && user.type === "god") {
      getStudentSessionHistory(student.user_id, "history")
        .catch(() => {
          setAlert({ message: tc("alerts.internalServerError"), error: true });
        })
        .then(({ response, error }) => {
          if (!error) {
            setSessionHistory((prevState) => {
              return [...prevState, ...response];
            });
          } else setAlert({ message: response, error });
        });

      getStudentSessionHistory(student.user_id, "upcoming")
        .catch(() => {
          setAlert({ message: tc("alerts.internalServerError"), error: true });
        })
        .then(({ response, error }) => {
          if (!error) {
            setSessionHistory((prevState) => {
              return [...prevState, ...response];
            });
          } else setAlert({ message: response, error });
        });
    }
    setLoading(false);
  }, [student, setAlert, user]);

  const {
    eng_name,
    chinese_name,
    birth_date,
    experience,
    level,
    stage,
    tribe_name,
    avatar,
    ccInfo,
  } = student;

  return (
    <div className={classes.mainContainer}>
      <Typography className={classes.title} variant="h3" component="h2">
        Student Profile
      </Typography>
      <Card className={classes.profileCard}>
        <Avatar
          className={classes.avatar}
          alt={eng_name + " " + chinese_name}
          src={avatar}
        />
        <CardContent>
          <Typography
            color="primary"
            align="center"
            variant="h5"
            component="h2"
          >
            {eng_name + " " + chinese_name}
          </Typography>
          <List>
            <ListItem>
              <Typography className={classes.listItem}>
                {ta("forms.birthDate")}:{" "}
                {birth_date?.substring(0, birth_date.indexOf("T"))}
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>

      <ListItemText
        classes={{
          primary: classes.statPrimary,
          secondary: classes.statSecondary,
        }}
        primary={tribe_name || "N/A"}
        secondary={tc("titles.tribe")}
      />
      <ListItemText
        classes={{
          primary: classes.statPrimary,
          secondary: classes.statSecondary,
        }}
        primary={experience}
        secondary={tc("titles.experience")}
      />
      <ListItemText
        classes={{
          primary: classes.statPrimary,
          secondary: classes.statSecondary,
        }}
        primary={level}
        secondary={tc("titles.level")}
      />
      <ListItemText
        classes={{
          primary: classes.statPrimary,
          secondary: classes.statSecondary,
        }}
        primary={stage}
        secondary={tc("titles.stage")}
      />

      {user.type === "god" && (
        <Box width="100%">
          <Typography className={classes.title} variant="h3" component="h2">
            Session History
          </Typography>

          <SessionHistory
            columns={columns}
            sessions={sessionHistory}
            style={{ margin: "1rem" }}
          />
        </Box>
      )}

      <Box width="100%">
        {ccInfo && ccInfo[0].length > 0 && (
          <Typography className={classes.title} variant="h3" component="h2">
            {tl("cc.card_plural")}
          </Typography>
        )}
        <div className={classes.ccContainer}>
          {ccInfo &&
            ccInfo[0]?.map((card) => {
              return (
                <Box style={{ margin: "1rem" }}>
                  <ChallengeCard key={card.id} {...card} />
                </Box>
              );
            })}
        </div>
      </Box>

      <LoadingComponent />
    </div>
  );
};

export default StudentProfile;
